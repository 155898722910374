import styled from 'styled-components';
import IconCheck from '../../images/icon-check.png';
import IconArrowGreenRight from '../../images/icon-arrow-yellow-right.png';

export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 15px 35px;
  margin-right: 10px;
  margin-bottom: 40px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: bold;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media (max-width: 1199px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
  }
  :hover {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
  }
`;
export const ParaText = styled.p`
  margin-bottom: 40px;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  ${ButtonStyle} {
    margin: 0;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
export const OrderedList = styled.ol`
  margin: 0 0 30px 0;
  padding: 0;
  list-style-type: none;
  counter-reset: standard-counter;
`;
export const ListItem = styled.li`
  padding: 0 0 15px 50px;
  background: url(${IconCheck}) no-repeat 0 1px;
  background-size: 30px auto;
  list-style: none;
  margin: 0;
  ${ParaText} {
    margin: 0;
  }
  @media (max-width: 767px) {
    padding-top: 1px;
    padding-left: 40px;
    background-position: 0 0;
    background-size: 25px auto;
  }
`;
export const ListItemArrow = styled.li`
  padding: 0 0 15px 30px;
  background: url(${IconArrowGreenRight}) no-repeat 0 1px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;
